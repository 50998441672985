import React, {
	forwardRef,
	useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { editor as monacoEditor } from 'monaco-editor';
import useStyles from 'isomorphic-style-loader/useStyles';

import classNames from 'classnames';
import {
	MappingSupportedLanguagesToCodeLanguages,
	SL_CODE_SUPPORTED_THEMES,
} from '../global/constants';

import s from './SlCodeEditor.scss';
import { CodeLanguages } from '../../../api/private/global.interface';
import { ICodeSolution } from '../../../api/public/learn-engine/learn-engine-api';
import { ProgrammingLanguageToCodeLanguages } from '../../../shared/public/constants/constants';

interface ISlCodeEditorProps {
	theme?: SL_CODE_SUPPORTED_THEMES;
	language?: CodeLanguages;
	value?: string;
	isLightMode?: boolean;
	isTIYWidget?: boolean;
	options?: monacoEditor.IStandaloneEditorConstructionOptions;
	height?: string;
	width?: string;
	onChange?: (data: { value: string; lng: CodeLanguages; }) => void;
	onFocus?: () => void;
	highestTab?: {
		lng: CodeLanguages,
		height: number;
	};
	onBlur?: () => void;
	onContentHeightChange?: (lng: CodeLanguages, height: number) => void;
	paddingBeforeFirstLine?: number;
}

const defaultOptions: monacoEditor.IStandaloneEditorConstructionOptions = {
	overviewRulerLanes: 0,
	fontSize: 14,
	lineHeight: 16.8,
	fontWeight: '400',
	autoClosingBrackets: 'languageDefined',
	autoClosingQuotes: 'languageDefined',
	automaticLayout: true,
	wordBasedSuggestions: true, // no autocomplete
	quickSuggestions: true, // no autocomplete
	readOnly: false,
	scrollBeyondLastLine: false,
	fixedOverflowWidgets: true,
	minimap: {
		enabled: false,
	},
	selectOnLineNumbers: true,
	contextmenu: true,
	scrollBeyondLastColumn: 30,
	scrollbar: {
		useShadows: false,
		vertical: 'auto',
		horizontal: 'auto',
		horizontalSliderSize: 9,
		verticalScrollbarSize: 9,
		alwaysConsumeMouseWheel: false,
	},
};

export const SlCodeEditor = forwardRef(({
	value = null,
	height = '100%',
	width = '100%',
	options,
	theme = SL_CODE_SUPPORTED_THEMES.DARK,
	language = CodeLanguages.PHP,
	onChange = () => { },
	onFocus,
	onBlur,
	isLightMode,
	highestTab,
	onContentHeightChange,
	isTIYWidget,
	paddingBeforeFirstLine = 1,
}: ISlCodeEditorProps, ref): JSX.Element => {
	useStyles(s);
	const initialValue = value;

	const [containerElement, setContainerElement] = useState<HTMLElement>(undefined);
	const [editorValue, setEditorValue] = useState(null);

	const onInputChange = (input: string): void => {
		onChange({ lng: language, value: input });
	};

	const editorInstanceRef = useRef(null);

	const focusEditorToLastChar = () => {
		const model = editorInstanceRef.current.getModel();
		const lastLineNumber = model.getLineCount();
		const lastColumn = model.getLineMaxColumn(lastLineNumber);
		editorInstanceRef.current.setPosition({ lineNumber: lastLineNumber, column: lastColumn });
		editorInstanceRef.current.focus();
	};

	const resetCodes = (codes: ICodeSolution[]) => {
		editorValue.setValue(codes.find((c) => ProgrammingLanguageToCodeLanguages[c.languageId] === language).code);
	};

	useImperativeHandle(ref, () => ({
		focusEditorToLastChar,
		resetCodes,
	}));

	useEffect(() => {
		if (isTIYWidget && editorInstanceRef.current) {
			let height = highestTab.height;
			const model = editorInstanceRef.current.getModel();
			const lineCount = model.getLineCount();
			if ((highestTab.lng === language) && lineCount > 1) { // Monaco editor height bug fix
				height += 12;
			}
			containerElement.style.height = `${height}px`;
			editorInstanceRef.current.layout({ width: containerElement.offsetWidth, height });
		}
	}, [highestTab?.height, isTIYWidget]);

	const initMonacoEditor = (): void => {
		if (containerElement) {
			monacoEditor.defineTheme(SL_CODE_SUPPORTED_THEMES.DARK, {
				base: 'vs-dark',
				inherit: true,
				rules: [],
				colors: {
					'editor.background': '#18191C',
					'editor.lineHighlightBackground': '#202122',
				},
			});
			monacoEditor.defineTheme(SL_CODE_SUPPORTED_THEMES.LIGHT, {
				base: 'vs',
				inherit: true,
				rules: [],
				colors: {
					'editor.background': '#FFFFFF',
					'editor.lineHighlightBackground': '#e3e8ec',
				},
			});

			editorInstanceRef.current = monacoEditor.create(containerElement, {
				value: initialValue,
				language: MappingSupportedLanguagesToCodeLanguages[language],
				theme: isTIYWidget ? SL_CODE_SUPPORTED_THEMES.DARK : theme,
				lineDecorationsWidth: 0,
				lineNumbersMinChars: 3,
				...defaultOptions,
				...options,
			});

			editorInstanceRef.current.changeViewZones((changeAccessor) => {
				const domNode = document.createElement('div');
				changeAccessor.addZone({
					afterLineNumber: 0,
					heightInLines: paddingBeforeFirstLine,
					domNode,
				});
			});

			setEditorValue(editorInstanceRef.current);
			editorInstanceRef.current.onDidChangeModelContent((e) => {
				if (!e.isFlush) {
					onInputChange(editorInstanceRef.current.getValue());
				}
			});

			if (isTIYWidget) {
				editorInstanceRef.current.onDidContentSizeChange(() => {
					const contentHeights = Math.min(1000, editorInstanceRef.current.getContentHeight());
					onContentHeightChange(language, contentHeights + 55);
				});

				editorInstanceRef.current.onDidFocusEditorText(() => {
					onFocus();
				});

				editorInstanceRef.current.onDidBlurEditorWidget(() => {
					onBlur();
				});
			}
		}
	};

	useEffect(() => {
		if (isTIYWidget) {
			monacoEditor.setTheme(SL_CODE_SUPPORTED_THEMES.DARK);
		}
		monacoEditor.setTheme(theme);
	}, [theme, isTIYWidget]);

	useEffect(() => {
		initMonacoEditor();
	}, [containerElement]);

	const editorRef = (component: HTMLElement): void => {
		setContainerElement(component);
	};

	return (value !== null ?
		<div
			onMouseEnter={(event) => {
				event.stopPropagation();
			}}
			className={classNames({
				light: isLightMode,
			})}
			onKeyDown={(e) => e.stopPropagation()}
			ref={editorRef}
			style={{ width, height }}
		/>
		: null);
});
