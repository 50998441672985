import { RefObject } from 'react';

import {
	windowLoopProtectionHandler,
	windowOnErrorSetter,
} from './externalScripts';
import { getTransformedJsCode } from './codeTransformation';
import { CodeLanguages } from '../../../../api/private/global.interface';

export const setSuccessDialogClass = (
	successDialogRef: RefObject<HTMLDivElement>,
	tailWidth: string,
): void => {
	if (successDialogRef?.current) {
		// eslint-disable-next-line no-param-reassign
		successDialogRef.current.style.width = `calc(100% - ${tailWidth})`;
		const clientWidth: number = successDialogRef.current.clientWidth;
		const cName = 'sl-success-dialog';
		let tail = '';
		if (clientWidth < 200) {
			tail = '__xs';
		} else if (clientWidth < 350) {
			tail = '__sm';
		} else if (clientWidth < 478) {
			tail = '__md';
		} else {
			tail = '';
		}
		// eslint-disable-next-line no-param-reassign
		successDialogRef.current.className = `${cName}${
			tail ? ` ${cName}${tail}` : ''
		}`;
	}
};

export const wrapByTag = (code: string, tag: string): string => {
	const hasTag = code ? code.includes(tag) : false;
	if (!hasTag) {
		return `<${tag}>
						${code || ''}
</${tag}>`;
	}
	return code;
};

function functionToString(func): string {
	return `${func.toString().match(/function[^{]+\{([\s\S]*)\}$/)[1]};`;
}

export const getStructuredWebCode = (
	sourceCode = '',
	cssCode = '',
	jsCode = '',
): string => {
	const localSourceCode = sourceCode.replace(/<!--(?!>)[\S\s]*?-->/g, '');
	let htmlCode = wrapByTag(localSourceCode, 'html');
	let css = '';
	let js = '';
	const externalScript = `<script>${functionToString(
		windowOnErrorSetter,
	)}${functionToString(windowLoopProtectionHandler)}</script>`;
	if (cssCode && cssCode.trim()) {
		css = `<style>${cssCode} </style>`;
	}
	if (jsCode && jsCode.trim()) {
		js = `<script>${getTransformedJsCode(jsCode)}</script>`;
	}
	const includeBody = localSourceCode
		? localSourceCode.includes('<body>')
		: false;
	const includeHead = localSourceCode
		? localSourceCode.includes('<head>')
		: false;
	const htmlTagIndex = htmlCode.indexOf('<html>') + 6;

	if (!includeHead) {
		htmlCode = [
			htmlCode.slice(0, htmlTagIndex),
			`<head>${externalScript}${css}</head>`,
			htmlCode.slice(htmlTagIndex),
		].join('');
	} else {
		const closeHeadTagIndex = htmlCode.indexOf('<head>') + 6;
		htmlCode = [
			htmlCode.slice(0, closeHeadTagIndex),
			`${externalScript}${css}`,
			htmlCode.slice(closeHeadTagIndex),
		].join('');
	}
	if (!includeBody) {
		const closeHtmlTagIndex = htmlCode.indexOf('</html>');
		htmlCode = [
			htmlCode.slice(0, closeHtmlTagIndex),
			`<body>${js}</body>`,
			htmlCode.slice(closeHtmlTagIndex),
		].join('');
	} else {
		const closeBodyTagIndex = htmlCode.indexOf('</body>');
		htmlCode = [
			htmlCode.slice(0, closeBodyTagIndex),
			js,
			htmlCode.slice(closeBodyTagIndex),
		].join('');
	}

	return htmlCode;
};

export const checkIsWeb = (language: CodeLanguages): boolean => (
	language === CodeLanguages.WEB
        || language === CodeLanguages.JS
        || language === CodeLanguages.HTML
        || language === CodeLanguages.CSS
);
