import React, {
	Children,
	cloneElement,
	createContext,
	createRef,
	CSSProperties,
	ReactElement,
	ReactNode,
	useCallback,
	useContext,
} from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import withStyles from 'isomorphic-style-loader/withStyles';

import classNames from 'classnames';
import { ContainerType, Orientation } from '../types/types';
import { setSuccessDialogClass } from '../utils/helperFunctions';

import s from './SplitPane.scss';

const SplitPaneContext = createContext(null);
const MIN_WIDTH = 48;
const MIN_HEIGHT = 56;

export interface IResizerProps {
    horizontal: boolean;
    id: string;
    isDarkMode?: boolean;
}

export interface ICollapsedPanels {
    state?: boolean;
    currentId?: string;
    orientation?: Orientation;
}

export function Resizer({
	horizontal,
	id,
	isDarkMode,
}: IResizerProps): JSX.Element {
	useStyles(s);
	const { addResizer } = useContext(SplitPaneContext);
	const refCallback = useCallback((node) => {
		addResizer(node);
	}, []);

	return (
		<div
			data-id={id}
			className={classNames('resizer', {
				horizontal, // now only in console is vertical need to change more dynamic
				dark: isDarkMode,
			})}
			ref={refCallback}
		>
			<div className={classNames('resizer-line-wrapper', {
				horizontal,
				dark: isDarkMode,
			})}
			>
				<div className="resizer-line" />
				<div className="resizer-line" />
				<div className="resizer-line" />
			</div>
		</div>
	);
}

export interface ISplitPaneProps {
    horizontal?: boolean;
    onResize?: (sizes: { id: string; width: number }[]) => void;
    onMouseUp?: () => void;
    onMouseDown?: () => void;
    className?: string;
    collapsedPanels?: ICollapsedPanels;
    callbackCollapseClicked: (id: string) => void;
    defaultWidth: number;
    isWeb?: boolean;
    type?: ContainerType;
    defaultCollapsedStatesKeys?: string[];
    components?: {
        loader?: JSX.Element;
        successDialog?: JSX.Element;
    };
}

export interface ISplitPaneState {
    resizer: any;
}

class SplitPane extends React.Component<
    ISplitPaneProps,
    ISplitPaneState
> {
	private currentDragNode: HTMLElement = null;

	private offset = 0;

	private offsetRemainder = 0;

	private currentXPosition = 0;

	private currentYPosition = 0;

	private wrapperWidth = 0;

	private wrapperHeight = 0;

	private sectionTotalWidth = 0;

	private sectionTotalHeight = 0;

	private resizerWith = 8;

	private wrapperRef = createRef<HTMLDivElement>();

	private animationRef = createRef<HTMLDivElement>();

	private successDialogRef = createRef<HTMLDivElement>();

	constructor(props: ISplitPaneProps) {
		super(props);

		this.state = {
			resizer: new Set(null),
		};
	}

	componentDidMount(): void {
		const {
			horizontal,
			onResize,
			children,
			defaultCollapsedStatesKeys,
			isWeb,
		} = this.props;
		const { resizer } = this.state;
		const sizes = [];
		Children.map(children, (child: ReactElement) => {
			sizes.push({
				width: child.props?.width ?? 100,
				height: child.props?.height ?? 100,
			});
		});

		if (sizes) {
			const changes = [];
			const panels = Array.from(
                this.wrapperRef.current.getElementsByClassName('panel panel--horizontal') as HTMLCollectionOf<HTMLElement>,
			);

			panels.forEach((p, i) => {
				const panel = Object.assign(p);
				if (horizontal && panel) {
					if (defaultCollapsedStatesKeys?.length) {
						const collapsedViewWidth = (100 * MIN_WIDTH)
                            / this.wrapperRef.current.clientWidth;
						// Todo Temporary solution only for this case
						if (
							defaultCollapsedStatesKeys.toString() === '1,2'
                            && isWeb
						) {
							if (i === 0) {
								panel.style.width = sizes[i]?.width;
							} else if (
								defaultCollapsedStatesKeys.includes(
									i.toString(),
								)
							) {
								panel.style.width = `${collapsedViewWidth}%`;
							} else {
								panel.style.width = `${100
                                    - 2 * collapsedViewWidth
                                    - (parseFloat(sizes[0]?.width) || 0)
								}%`;
							}
						} else if (
							defaultCollapsedStatesKeys.includes(i.toString())
						) {
							panel.style.width = `${collapsedViewWidth}%`;
						} else if (
							i <= defaultCollapsedStatesKeys.length
                            && i > 0
						) {
							panel.style.width = `${50 - 2 * collapsedViewWidth
							}%`;
						} else {
							panel.style.width = sizes[i]?.width;
						}
					} else {
						panel.style.width = sizes[i]?.width;
					}

					changes.push({
						id: panel.dataset.id,
						width: panel.clientWidth,
					});
				}
			});

			if (onResize && changes.length > 0 && horizontal) {
				onResize(changes);
			}
		}

		const resizerNodes = Array.from(resizer);

		resizerNodes.forEach((node: HTMLElement) => {
			node.addEventListener('mousedown', this.mouseDown);
		});

		this.wrapperRef.current.addEventListener('mousemove', this.mouseMove);
		window.addEventListener('mouseup', this.mouseUp);
	}

	componentDidUpdate(): void {
		const {
			defaultWidth,
			collapsedPanels,
			callbackCollapseClicked,
			horizontal,
		} = this.props;
		// Todo refactor bellow logic, and add shouldcomponentupdate
		if (collapsedPanels?.state && horizontal) {
			if (collapsedPanels?.orientation === 'horizontal') {
				const panels = Array.from(
                    this.wrapperRef.current.getElementsByClassName('panel panel--horizontal') as HTMLCollectionOf<HTMLElement>,
				);
				const currentId = +collapsedPanels.currentId;
				let searchablePanel = null;
				for (let i = currentId + 1; i <= panels.length - 1; i++) {
					const currentPanelWidth = panels[i]?.style.width;
					if (
						currentPanelWidth
                        && +currentPanelWidth.toString().slice(0, -1)
                        > defaultWidth
					) {
						searchablePanel = panels[i];
					}
				}
				if (!searchablePanel) {
					for (let i = currentId - 1; i >= 0; i--) {
						const currentPanelWidth = panels[i]?.style.width;

						if (
							currentPanelWidth
                            && +currentPanelWidth.toString().slice(0, -1)
                            > defaultWidth
						) {
							searchablePanel = panels[i];
						}
					}
				}
				const searchablePanelWidth = searchablePanel?.style.width;
				const searchablePanelSlicedWidth = +searchablePanelWidth.slice(
					0,
					-1,
				);
				const currentPanelWidth = +panels[currentId].style.width.slice(
					0,
					-1,
				);

				const newSearchablePercent = searchablePanelSlicedWidth
                    - defaultWidth
                    + currentPanelWidth;

				const newCurrentPercent = defaultWidth;

				if (searchablePanel) {
					searchablePanel.style.width = `${newSearchablePercent}%`;
				}

				if (panels[currentId]) {
					panels[currentId].style.width = `${newCurrentPercent}%`;
				}

				callbackCollapseClicked(collapsedPanels.currentId.toString());

				this.setAnimationLoaderStyle(panels);
				this.setSuccessDialogStyle(panels);
			}
		}
	}

	componentWillUnmount(): void {
		const { resizer } = this.state;
		const resizerNodes = Array.from(resizer);
		resizerNodes.forEach((node: HTMLElement) => {
			node.removeEventListener('mousedown', this.mouseDown);
		});
		this.wrapperRef.current.removeEventListener(
			'mousemove',
			this.mouseMove,
		);
		window.removeEventListener('mouseup', this.mouseUp);
	}

	mouseDown = (event: MouseEvent): void => {
		const { onMouseDown } = this.props;
		if (onMouseDown) {
			onMouseDown();
		}
		this.currentDragNode = event.target as HTMLElement;

		this.currentXPosition = event.clientX;
		this.currentYPosition = event.clientY;

		this.wrapperWidth = this.wrapperRef.current.clientWidth;
		this.wrapperHeight = this.wrapperRef.current.clientHeight;

		const panels = Array.from(
            this.wrapperRef.current.getElementsByClassName('panel') as HTMLCollectionOf<HTMLElement>,
		);

		const currentId = +this.currentDragNode.dataset.id;
		const leftPixels = panels[currentId - 1].clientWidth;
		const rightPixels = panels[currentId].clientWidth;

		this.sectionTotalWidth = leftPixels + rightPixels;
	};

	mouseMove = (event: MouseEvent): void => {
		if (!this.currentDragNode || this.wrapperWidth === 0) return;

		const { horizontal } = this.props;

		if (horizontal) {
			this.horizontalCalculationHandler(event);
		} else {
			this.verticalCalculationHandler(event);
		}
	};

	mouseUp = (): void => {
		const { onMouseUp } = this.props;
		if (onMouseUp) {
			onMouseUp();
		}
		this.currentDragNode = null;
		this.offset = 0;
	};

	floorIfSmaller = (number: number, small = 0.1): number => {
		const floored = Math.floor(number);
		const diff = number - floored;
		if (diff < small) {
			return floored;
		}
		return number;
	};

	getPanelSize = (
		panel: HTMLElement,
		orientation: Orientation,
	): { width?: number; height?: number } => {
		if (orientation === 'horizontal') {
			const width = (this.wrapperWidth / 100) * parseFloat(panel.style.width);
			return { width: this.floorIfSmaller(width) };
		}

		if (orientation === 'vertical') {
			const rect = panel.getClientRects()[0];

			return {
				height: this.floorIfSmaller(rect.height),
			};
		}
		return {};
	};

	horizontalCalculationHandler = ({ clientX }: MouseEvent): void => {
		const { onResize } = this.props;

		const currentId = +this.currentDragNode.dataset.id;
		const panels = Array.from(
            this.wrapperRef.current.getElementsByClassName(
            	'panel panel--horizontal',
            ) as HTMLCollectionOf<HTMLElement>,
		);
		let panelRightOffset = 1;
		let panelLeftOffset = 1;
		let leftId = currentId === 0 ? 0 : currentId - 1;
		let rightId = currentId;

		this.offset = clientX - this.currentXPosition + this.offsetRemainder;
		this.offsetRemainder = 0;

		if (this.offset === 0) {
			return;
		}

		let leftPanel = panels[currentId - 1];
		let rightPanel = panels[currentId];

		let leftPixels = this.getPanelSize(leftPanel, 'horizontal').width;
		let rightPixels = this.getPanelSize(rightPanel, 'horizontal').width;

		const RESIZER_WIDTH = 8;

		if (this.offset > 0) {
			let nextId = currentId + panelRightOffset;
			let widths = leftPixels;
			while (
				(rightPixels <= RESIZER_WIDTH
                    || (MIN_WIDTH !== undefined && rightPixels <= MIN_WIDTH))
                && panels[nextId] !== undefined
                && panels.indexOf(panels[nextId]) !== panels.length
			) {
				rightPanel = panels[nextId];
				rightPixels = this.getPanelSize(rightPanel, 'horizontal').width;
				panelRightOffset++;
				nextId = currentId + panelRightOffset;
				widths
                    += rightPixels
                        > (MIN_WIDTH !== undefined ? MIN_WIDTH : RESIZER_WIDTH)
                    	? rightPixels
                    	: 0;
			}
			if (panelRightOffset !== 1) {
				rightId = currentId + panelRightOffset - 1;
			}
			if (widths !== leftPixels) {
				this.sectionTotalWidth = widths;
			} else {
				this.sectionTotalWidth = leftPixels + rightPixels;
			}
		} else if (this.offset < 0) {
			let nextId = currentId - panelLeftOffset;
			let widths = rightPixels;
			while (
				(leftPixels <= RESIZER_WIDTH
                    || (MIN_WIDTH !== undefined && leftPixels <= MIN_WIDTH))
                && panels[nextId] !== undefined
			) {
				leftPanel = panels[nextId];
				leftPixels = this.getPanelSize(leftPanel, 'horizontal').width;
				panelLeftOffset++;
				nextId = currentId - panelLeftOffset;
				widths
                    += leftPixels
                        > (MIN_WIDTH !== undefined ? MIN_WIDTH : RESIZER_WIDTH)
                    	? leftPixels
                    	: 0;
			}
			if (panelLeftOffset !== 1) {
				leftId = currentId - panelLeftOffset + 1;
			}
			if (widths !== rightPixels) {
				this.sectionTotalWidth = widths;
			} else {
				this.sectionTotalWidth = leftPixels + rightPixels;
			}
		}

		rightPixels -= this.offset;
		leftPixels = this.sectionTotalWidth - rightPixels;

		if (rightPixels < RESIZER_WIDTH) {
			rightPixels = RESIZER_WIDTH;
			leftPixels = this.sectionTotalWidth - rightPixels;
		} else if (leftPixels < RESIZER_WIDTH) {
			leftPixels = RESIZER_WIDTH;
			rightPixels = this.sectionTotalWidth - leftPixels;
		}

		if (MIN_WIDTH !== undefined) {
			if (rightPixels < MIN_WIDTH) {
				rightPixels = MIN_WIDTH;
				leftPixels = this.sectionTotalWidth - rightPixels;
			} else if (leftPixels < MIN_WIDTH) {
				leftPixels = MIN_WIDTH;
				rightPixels = this.sectionTotalWidth - leftPixels;
			}
		}

		const sectionTotalPercent = (this.sectionTotalWidth * 100) / this.wrapperWidth;
		const newRightPercent = (100 * rightPixels) / this.wrapperWidth;
		const newLeftPercent = sectionTotalPercent - newRightPercent;

		rightPanel.style.width = `${newRightPercent}%`;
		leftPanel.style.width = `${newLeftPercent}%`;

		if (onResize) {
			onResize([
				{
					id: leftId.toString(),
					width: leftPixels,
				},
				{
					id: rightId.toString(),
					width: rightPixels,
				},
			]);
		}
		this.currentXPosition = clientX;
		this.setAnimationLoaderStyle(panels);
		this.setSuccessDialogStyle(panels);
	};

	verticalCalculationHandler = ({ clientY }: MouseEvent): void => {
		const currentId = +this.currentDragNode.dataset.id;
		const panels = Array.from(
            this.wrapperRef.current.getElementsByClassName(
            	'panel panel--vertical',
            ) as HTMLCollectionOf<HTMLElement>,
		);

		this.offset = clientY - this.currentYPosition;

		if (this.offset === 0) {
			return;
		}

		const topPanel = panels[currentId - 1];
		const bottomPanel = panels[currentId];

		let { height: topPixel } = this.getPanelSize(topPanel, 'vertical');
		let { height: bottomPixel } = this.getPanelSize(
			bottomPanel,
			'vertical',
		);
		this.sectionTotalHeight = topPixel + bottomPixel;
		bottomPixel -= this.offset;
		topPixel = this.sectionTotalHeight - bottomPixel;

		if (bottomPixel < MIN_HEIGHT) {
			bottomPixel = MIN_HEIGHT;
		} else if (topPixel < MIN_HEIGHT) {
			topPixel = MIN_HEIGHT;
			bottomPixel = this.sectionTotalHeight - topPixel;
		}

		const bottomPercent = (100 * bottomPixel) / this.wrapperHeight;
		const topPercent = 100 - bottomPercent;

		bottomPanel.style.height = `${bottomPercent}%`;
		topPanel.style.height = `${topPercent}%`;
		this.currentYPosition = clientY;
	};

	setAnimationLoaderStyle = (panels: HTMLElement[]): void => {
		if (this.animationRef.current) {
			const { type } = this.props;
			if (type === ContainerType.codeRepo) {
				this.animationRef.current.style.width = `calc(100% - ${panels[0].style.width}`;
			} else {
				this.animationRef.current.style.width = `calc(100% - ${panels[panels.length - 1].style.width
				})`;
			}
		}
	};

	setSuccessDialogStyle = (panels: HTMLElement[]): void => {
		const { type } = this.props;
		if (type === ContainerType.codeRepo) {
			setSuccessDialogClass(
				this.successDialogRef,
				`${panels[0].style.width} - ${this.resizerWith}px`,
			);
		}
	};

	addResizer = (node: ReactNode): void => {
		if (node) {
			const { resizer } = this.state;
			resizer.add(node);
			this.setState({
				resizer,
			});
		}
	};

	codeLoaderStyles = (): CSSProperties => {
		const { type } = this.props;

		if (type === ContainerType.codeRepo) {
			return {
				width: '50%',
				left: 'auto',
				right: 0,
			};
		}
		return {
			width: '75%',
		};
	};

	render(): ReactElement {
		const {
			horizontal, children, className, components, isWeb, type,
		} = this.props;

		const { resizer } = this.state;
		const childrenCount = Children.count(children);

		return (
			<div
				className={`split-pane ${className || ''}`}
				ref={this.wrapperRef}
			>
				<SplitPaneContext.Provider
					value={{
						resizer,
						addResizer: this.addResizer,
					}}
				>
					{Children.map(
						children,
						(child: ReactElement, i: number) => cloneElement(
							child,
							{
								...child.props,
								_id: i,
								isLast: i === childrenCount,
								horizontal,
								hideResizer: i === 0,
							},
							child.props.children,
						),
					)}

					{isWeb
                        && components?.loader
                        && cloneElement(components.loader, {
                        	...components.loader.props,
                        	styles: this.codeLoaderStyles(),
                        	ref: this.animationRef,
                        })}

					{type === ContainerType.codeRepo
                        && components?.successDialog
                        && cloneElement(components.successDialog, {
                        	...components.successDialog.props,
                        	successDialogRef: this.successDialogRef,
                        	wrapperRef: this.wrapperRef,
                        })}
				</SplitPaneContext.Provider>
			</div>
		);
	}
}

const StyledSplitPane = withStyles(s)(SplitPane);

export { StyledSplitPane as SplitPane };
