import { useEffect } from 'react';

export const useConfirmLeave = (pageHasChanges: boolean): boolean => {
	useEffect(() => {
		if (pageHasChanges) {
			window.onbeforeunload = (e: BeforeUnloadEvent) => {
				e.returnValue = 'Are you sure to leave? Your changes may not be saved.';
				return 'Are you sure to leave? Your changes may not be saved.';
			};
		} else {
			window.onbeforeunload = null;
		}
	}, [pageHasChanges]);

	useEffect(() => () => {
		window.onbeforeunload = null;
	}, []);

	return true;
};
