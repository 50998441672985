import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import s from './RunButton.scss';
import { i18n } from '../../../../symphony';
import { PlayIcon } from '../../assets/icons/PlayIcon';

interface RunButtonProps {
	onClick: () => void;
	className?: string;
}

export const RunButton: React.FC<RunButtonProps> = ({ onClick, className }) => {
	useStyles(s);

	return (
		<div
			className={classNames('sl-playground__run-btn', className)}
			onClick={onClick}
		>
			<span className="sl-playground__run-btn__text">
				{i18n.t('web-playground.run-button')}
			</span>
			<PlayIcon />
		</div>
	);
};
