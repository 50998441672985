import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';

import { SlSwitchToggle } from '../../SlSwitchToggle/SlSwitchToggle';

import s from './ToggleBtn.scss';

export interface ToggleBtnProps {
	onChange?: (state: boolean) => void;
	state?: boolean;
	disabled?: boolean;
	title: string | React.ReactNode;
	isDarkMode?: boolean;
}

export function ToggleBtn({
	onChange,
	state,
	disabled,
	title,
	isDarkMode,
}: ToggleBtnProps): JSX.Element {
	useStyles(s);

	return (
		<label className="sl-toggle-btn">
			<SlSwitchToggle
				disabled={disabled}
				checked={state}
				onChange={onChange}
			/>
			<span
				className={classNames('sl-toggle-btn__title', {
					dark: isDarkMode,
				})}
			>
				{title}
			</span>
		</label>
	);
}
