import React from 'react';

interface IOverlapLayerProps {
    isResizingPanel: boolean;
}

export function OverlapLayer({
	isResizingPanel,
}: IOverlapLayerProps): JSX.Element {
	return isResizingPanel ? (
		<div
			style={{
				height: '100%',
				width: '100%',
				position: 'absolute',
				zIndex: 3,
			}}
		/>
	) : null;
}
