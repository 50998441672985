import { infiniteLoopProtectInstrument } from './infinite-loop-protect-instrument';

export const getTransformedJsCode = (jsCode: string): string => {
	const sourceCode = `(function(){${jsCode}})()`; // temporary comment
	try {
		return infiniteLoopProtectInstrument(sourceCode);
	} catch (err) {
		return sourceCode;
	}
};
