import React, {
	SyntheticEvent, useRef,
} from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import classNames from 'classnames';
import { IOutput } from '../types/types';

import s from './OutputIframe.scss';
import { ICodeOutput } from '../../../../api/private/learn-engine-api/learn-engine-api.interface';
import { useIFrameSrc } from '../utils/hooks';

export interface OutputIframeProps {
    value: IOutput | ICodeOutput;
    onLoad?: (data?: SyntheticEvent<HTMLIFrameElement>) => void;
	iframeHeight?: number;
	isTIYWidget?: boolean;
}

export function OutputIframe({
	value,
	onLoad,
	iframeHeight,
	isTIYWidget,
}: OutputIframeProps): JSX.Element {
	useStyles(s);
	// eslint-disable-next-line
    const text = value ? new String(value.output || '') : ''; // for rendering when text is not changed

	const iframeRef = useRef(null);

	const onIframeLoad = (e: SyntheticEvent<HTMLIFrameElement>) => {
		if (onLoad) {
			onLoad(e);
		}
	};

	const iframeSrc = useIFrameSrc();

	return (
		<div className={classNames('sl-playground-output-web', {
			hide: iframeHeight === 0,
		})}
		>
			<iframe
				ref={iframeRef}
				title="Code Output"
				frameBorder="0"
				id="output-frame"
				className={classNames('sl-playground-output-web', {
					hide: iframeHeight === 0,
					isTIYWidget,
				})}
				src={iframeSrc}
				style={{ height: iframeHeight }}
				onLoad={onIframeLoad}
			/>
		</div>
	);
}
