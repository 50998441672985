import React, { useMemo } from 'react';
import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';

import { ProgrammingLanguage } from '../../../../symphony';
import { ProgrammingLanguageToCodeLanguages } from '../../constants/constants';

import s from './EditorLabels.scss';

interface EditorLabelsProps {
	languages: ProgrammingLanguage[];
	activeTab?: ProgrammingLanguage;
	onTabNameClick?: (lng: ProgrammingLanguage) => void;
	isDarkMode?: boolean;
}

export const EditorLabels = ({
	languages, activeTab, onTabNameClick, isDarkMode = false,
}: EditorLabelsProps) => {
	useStyles(s);
	const disableClick = useMemo(() => languages.length < 2, [languages]);

	return (
		<div className={classNames('sl-editor-tabs-names', {
			dark: isDarkMode,
		})}
		>
			{languages.map((language) => (
				<div
					key={language}
					className={classNames('sl-editor-tabs-names__name', {
						active: activeTab === language || disableClick,
						disableClick,
					})}
					onClick={() => onTabNameClick(language)}
				>
					{ProgrammingLanguageToCodeLanguages[language]}
				</div>
			))}
		</div>
	);
};
