import React, { CSSProperties, FC, forwardRef } from 'react';
import Lottie, { LottieComponentProps } from 'lottie-react';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './SlAnimationLoader.scss';

export interface ISlAnimationLoaderProps extends LottieComponentProps {
    show?: boolean;
    styles?: CSSProperties;
    className?: string;
    callback?: () => void;
}

export const SlAnimationLoader: FC<ISlAnimationLoaderProps> = forwardRef(
	(
		{
			show,
			className,
			animationData,
			styles,
			loop,
			lottieRef,
			autoplay,
			callback,
		}: ISlAnimationLoaderProps,
		ref,
	) => {
		useStyles(s);
		return (
			<div
				className={`sl-animation-loader ${
					className ? `sl-animation-loader__${className}` : ''
				} ${show ? '' : 'sl-animation-loader__hidden'}`}
				ref={ref}
				style={styles || {}}
			>
				<Lottie
					animationData={animationData}
					loop={loop}
					autoplay={autoplay}
					lottieRef={lottieRef}
					onComplete={callback}
				/>
			</div>
		);
	},
);
