import React, { memo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { Resizer } from '../SplitPane/SplitPane';

import s from './Panel.scss';

export interface IPanelProps {
    horizontal?: boolean;
    children?: React.ReactElement;
    hideResizer?: boolean;
    _id?: string;
    width?: string;
    style?: Record<string, string>;
    isResizing?: boolean;
    isDarkMode?: boolean;
}

export const Panel: React.FC<IPanelProps> = memo(
	({
		horizontal,
		children,
		hideResizer,
		_id,
		style,
		isResizing,
		isDarkMode = false,
	}): JSX.Element => {
		useStyles(s);
		const getPanelHeader = (): JSX.Element => {
			if (hideResizer) {
				return null;
			}

			return (
				<Resizer
					id={_id}
					horizontal={horizontal}
					isDarkMode={isDarkMode}
				/>
			);
		};

		return (
			<div
				data-id={_id}
				className={`panel panel--${
					horizontal ? 'horizontal' : 'vertical'
				}`}
				style={style}
			>
				{getPanelHeader()}
				<div
					className={`panel--layer${
						isResizing ? ' panel--layer__resizing' : ''
					}`}
				>
					{children}
				</div>
			</div>
		);
	},
);
