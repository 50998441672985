import { CodeLanguages } from '../../../../api/private/global.interface';
import { PlaygroundOutputType } from '../../../../api/private/learn-engine-api/learn-engine-api.interface';
import { IOutputType } from '../../../../api/public/code-api';

export type Orientation = 'horizontal' | 'vertical';

export enum ContainerType {
    playground = 'playground',
    tryItYourSelf = 'tryItYourSelf',
    codeRepo = 'codeRepo',
}

export interface IOutput {
    language?: CodeLanguages;
    output: string;
    outputStyle?: {
        dark: string;
        light: string;
    };
    outputType: IOutputType | PlaygroundOutputType;
}

export const CONSOLE_STYLES = {
	DARK: {},
	LIGHT: {
		LOG_ERROR_COLOR: '#ff002f',
		LOG_ERROR_BACKGROUND: '#ffeff0',
		LOG_ERROR_BORDER: 'none',
		LOG_COLOR: '#000',
	},
};
