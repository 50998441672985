import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './SlSwitchToggle.scss';

export interface IToggleSwitchProps {
    checked: boolean;
    onChange: (state: boolean) => void;
    disabled?: boolean;
}

export function SlSwitchToggle({
	checked,
	onChange,
	disabled,
}: IToggleSwitchProps): JSX.Element {
	useStyles(s);
	const [state, setState] = useState(checked);

	useEffect(() => {
		setState(checked);
	}, [checked]);

	const onCheckBoxChange = () => {
		onChange(!state);
		setState(!state);
	};

	return (
		<div className="sl-toggle-switch">
			<label className="sl-toggle-switch__switch round">
				<input
					onChange={onCheckBoxChange}
					checked={state}
					type="checkbox"
					disabled={disabled}
				/>
				<span className="toggle-slider round" />
			</label>
		</div>
	);
}
