import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './OutputHeader.scss';

interface IOutputHeaderProps {
	title: string;
}

export const OutputHeader: React.FC<IOutputHeaderProps> = ({ title }) => {
	useStyles(s);

	return (
		<div className="output-header">
			<div className="output-header__title">
				{title}
			</div>
		</div>
	);
};
