export const NotFoundPages = {
	MATERIAL_NOT_FOUND: {
		title: 'We can’t find that, sorry',
		description: 'You may not have access, or it may no longer exist.',
		button: {
			title: 'Add new',
		},
	},
};

export const lePlaygroundContext = {
	value: '',
	setValue: (context: string) => {
		lePlaygroundContext.value = context;
	},
};

export const tiyCodeInputModalId = 'tiyCodeInputModalId';

export const tiyCodeResetModalId = 'tiyCodeResetModalId';
