import React, { FC, memo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { SlIcon } from '@sololearnorg/icons';

import { Orientation } from '../types/types';

import s from './CollapsedView.scss';

export interface ICollapsedViewProps {
    title: string;
    isDarkMode: boolean;
    show?: boolean;
    onClick: () => void;
    orientation?: Orientation;
}

export const CollapsedView: FC<ICollapsedViewProps> = memo(
	({
		title, isDarkMode, show, onClick, orientation = 'horizontal',
	}) => {
		useStyles(s);
		return (
			<div
				onClick={onClick}
				className={`collapsed-view collapsed-view__${
					isDarkMode ? 'dark' : 'light'
				} collapsed-view__${
					show ? 'show' : 'hide'
				} collapsed-view__${orientation}`}
			>
				<span
					className={`collapsed-view__${
						isDarkMode ? 'dark' : 'light'
					}__title`}
				>
					{title}
				</span>
				<div className="collapsed-view__icon">
					<SlIcon iconId="caret-down" />
				</div>
			</div>
		);
	},
);
