import { CodeLanguages } from '../../../api/private/global.interface';

export enum SL_CODE_SUPPORTED_LANGUAGES {
	WEB = 'Web',
	C = 'c',
	CPP = 'cpp',
	CSharp = 'csharp',
	JAVA = 'java',
	KOTLIN = 'kotlin',
	PYTHON = 'python',
	RUBY = 'ruby',
	PHP = 'php',
	SWIFT = 'swift',
	NODEJS = 'javascript',
	JAVASCRIPT = 'javascript',
	HTML = 'html',
	CSS = 'css',
	SQL = 'sql',
	GO = 'go',
	R = 'r',
	TS = 'typescript',
}
export enum SL_CODE_SUPPORTED_THEMES {
	DARK = 'custom-dark',
	LIGHT = 'custom-light',
}

export const SL_CODE_DEFAULT_VALUES = {
	[SL_CODE_SUPPORTED_LANGUAGES.CPP]:
        '#include <iostream>\n'
        + '\n'
        + 'int main() {\n'
        + '    std::cout << "Hello World!";\n'
        + '    return 0;\n'
        + '}',
	[SL_CODE_SUPPORTED_LANGUAGES.C]: `#include <stdio.h>
int main() {
   // printf() displays the string inside quotation
   printf("Hello, World!");
   return 0;
}`,
	[SL_CODE_SUPPORTED_LANGUAGES.CSharp]:
        'using System;\n'
        + 'using System.Collections.Generic;\n'
        + 'using System.Linq;\n'
        + 'using System.Text;\n'
        + 'using System.Threading.Tasks;\n'
        + '\n'
        + 'namespace CSharpTutorials\n'
        + '{\n'
        + '    class Program\n'
        + '    {\n'
        + '        static void Main(string[] args)\n'
        + '        {\n'
        + '            string message = "Hello World!!";\n'
        + '\n'
        + '            Console.WriteLine(message);\n'
        + '        }\n'
        + '    }\n'
        + '}',
	[SL_CODE_SUPPORTED_LANGUAGES.JAVA]:
        'import java.util.Scanner;\n'
        + '\n'
        + 'public class HelloWorld {\n'
        + '\n'
        + '    public static void main(String[] args) {\n'
        + '\n'
        + '        // Creates a reader instance which takes\n'
        + '        // input from standard input - keyboard\n'
        + '        Scanner reader = new Scanner(System.in);\n'
        + '        System.out.print("Enter a number: ");\n'
        + '\n'
        + '        // nextInt() reads the next integer from the keyboard\n'
        + '        int number = reader.nextInt();\n'
        + '\n'
        + '        // println() prints the following line to the output screen\n'
        + '        System.out.println("You entered: " + number);\n'
        + '    }\n'
        + '}',
	[SL_CODE_SUPPORTED_LANGUAGES.KOTLIN]:
        'fun maxOf(a: Int, b: Int): Int {\n'
        + '    if (a > b) {\n'
        + '        return a\n'
        + '    } else {\n'
        + '        return b\n'
        + '    }\n'
        + '}',
	[SL_CODE_SUPPORTED_LANGUAGES.PYTHON]:
        '# This program adds two numbers\n'
        + '\n'
        + 'num1 = 1.5\n'
        + 'num2 = 6.3\n'
        + '\n'
        + '# Add two numbers\n'
        + 'sum = num1 + num2\n'
        + '\n'
        + '# Display the sum\n'
        + 'print(\'The sum of {0} and {1} is {2}\'.format(num1, num2, sum))',
	[SL_CODE_SUPPORTED_LANGUAGES.RUBY]:
        '# Here\'s a different way to add up an array.\n'
        + 'fred = [ 4, 19, 3, 7, 32 ]\n'
        + 'sum = 0\n'
        + 'fred.each { |i| sum += i }\n'
        + 'print "Sum of [", fred.join(" "), "] is #{sum}\\n"\n'
        + '\n'
        + '# Or create a secret message.\n'
        + 'key = { \'A\' => \'U\', \'B\' => \'Q\', \'C\' => \'A\', \'D\' => \'F\', \'E\' => \'D\', \'F\' => \'K\',\n'
        + '        \'G\' => \'P\', \'H\' => \'W\', \'I\' => \'N\', \'J\' => \'L\', \'K\' => \'J\', \'L\' => \'M\',\n'
        + '        \'M\' => \'S\', \'N\' => \'V\', \'O\' => \'Y\', \'P\' => \'O\', \'Q\' => \'Z\', \'R\' => \'T\',\n'
        + '        \'S\' => \'E\', \'T\' => \'I\', \'U\' => \'X\', \'V\' => \'B\', \'W\' => \'G\', \'X\' => \'H\',\n'
        + '        \'Y\' => \'R\', \'Z\' => \'C\' }\n'
        + 'print "\\nThe encoded message is: "\n'
        + '"The secret message".each_byte do | b |\n'
        + '    b = b.chr.upcase\n'
        + '    if key.has_key?(b) then\n'
        + '        print key[b]\n'
        + '    else\n'
        + '        print b\n'
        + '    end\n'
        + 'end\n'
        + 'print "\\n"\n'
        + '\n'
        + '# But give us the info to read it anyway.\n'
        + 'print "The key is: "\n'
        + 'ct = 8\n'
        + 'key.each { | k, v | \n'
        + '    if ct == 8 then \n'
        + '        print "\\n   "\n'
        + '        ct = 0\n'
        + '    else\n'
        + '        print ", "\n'
        + '    end\n'
        + '    ct = ct + 1\n'
        + '    print "#{v} => #{k}"  \n'
        + '}\n'
        + 'print "\\n\\n"\n'
        + '\n'
        + '# Some interesting things from Integer.\n'
        + '3.times { print "Hi! " }\n'
        + 'print "\\n"\n'
        + '\n'
        + 'print "Count: "\n'
        + '3.upto(7) { |n| print n, " " }\n'
        + 'print "\\n"',
	[SL_CODE_SUPPORTED_LANGUAGES.PHP]:
        '<?php\n'
        + '//initialize with a random integer within range\n'
        + '$diceNumber = mt_rand(1, 6);\n'
        + '\n'
        + '//initialize\n'
        + '$numText = "";\n'
        + '\n'
        + '//calling switch statement\n'
        + '  switch($diceNumber) \n'
        + '  {\n'
        + '  case 1:\n'
        + '    $numText = "One";\n'
        + '    break;\n'
        + '  case 2:\n'
        + '    $numText = "Two";\n'
        + '    break;\n'
        + '  case 3:\n'
        + '  case 4:\n'
        + '    // case 3 and 4 will go to this line\n'
        + '    $numText = "Three or Four";\n'
        + '    break;\n'
        + '  case 5:\n'
        + '    $numText = "Five";\n'
        + '    echo $numText;\n'
        + '    // break; //without specify break or return it will continue execute to next case.\n'
        + '  case 6:\n'
        + '    $numText = "Six";\n'
        + '    echo $numText;\n'
        + '    break;\n'
        + '  default:\n'
        + '    $numText = "unknown";\n'
        + '  }\n'
        + '  \n'
        + '  //display result\n'
        + '  echo \'Dice show number \'.$numText.\'.\';\n'
        + '\n'
        + '?>',
	[SL_CODE_SUPPORTED_LANGUAGES.SWIFT]:
        'import UIKit\n'
        + 'class ViewController: UIViewController {\n'
        + '    \n'
        + '    override func viewDidLoad() {\n'
        + '        super.viewDidLoad()\n'
        + '        // Do any additional setup after loading the view, typically from a nib.\n'
        + '        \n'
        + '        // Create a DatePicker\n'
        + '        let datePicker: UIDatePicker = UIDatePicker()\n'
        + '        \n'
        + '        // Posiiton date picket within a view\n'
        + '        datePicker.frame = CGRect(x: 10, y: 50, width: self.view.frame.width, height: 200)\n'
        + '        \n'
        + '        // Set some of UIDatePicker properties\n'
        + '        datePicker.timeZone = NSTimeZone.local\n'
        + '        datePicker.backgroundColor = UIColor.white\n'
        + '        \n'
        + '        // Add an event to call onDidChangeDate function when value is changed.\n'
        + '        datePicker.addTarget(self, action: #selector(ViewController.datePickerValueChanged(_:)), for: .valueChanged)\n'
        + '   \n'
        + '        // Add DataPicker to the view\n'
        + '        self.view.addSubview(datePicker)\n'
        + '        \n'
        + '    }\n'
        + '    \n'
        + '    \n'
        + '    func datePickerValueChanged(_ sender: UIDatePicker){\n'
        + '        \n'
        + '        // Create date formatter\n'
        + '        let dateFormatter: DateFormatter = DateFormatter()\n'
        + '        \n'
        + '        // Set date format\n'
        + '        dateFormatter.dateFormat = "MM/dd/yyyy hh:mm a"\n'
        + '        \n'
        + '        // Apply date format\n'
        + '        let selectedDate: String = dateFormatter.string(from: sender.date)\n'
        + '        \n'
        + '        print("Selected value \\(selectedDate)")\n'
        + '    }\n'
        + '    \n'
        + '    override func didReceiveMemoryWarning() {\n'
        + '        super.didReceiveMemoryWarning()\n'
        + '        // Dispose of any resources that can be recreated.\n'
        + '    }\n'
        + '    \n'
        + '}',
	[SL_CODE_SUPPORTED_LANGUAGES.JAVASCRIPT]: '',
	[SL_CODE_SUPPORTED_LANGUAGES.HTML]: `<!DOCTYPE HTML>
<!--
\tComments are overrated
-->
<html>
<head>
\t<title>HTML Sample</title>
\t<style type="text/css">
\t\th1 {
\t\t\tcolor: #CCA3A3;
\t\t}
\t</style>
\t<script type="text/javascript">
\t\twindow.alert("I am a sample...");
\t</script>
</head>
<body>
\t<h1>Heading No.1</h1>
\t<input disabled type="button" value="Click me" />
</body>
</html>
`,
	[SL_CODE_SUPPORTED_LANGUAGES.CSS]: 'font-size: 17px',
};

export const MappingSupportedLanguagesToCodeLanguages = {
	[CodeLanguages.CPP]: SL_CODE_SUPPORTED_LANGUAGES.CPP,
	[CodeLanguages.C]: SL_CODE_SUPPORTED_LANGUAGES.C,
	[CodeLanguages.CSharp]: SL_CODE_SUPPORTED_LANGUAGES.CSharp,
	[CodeLanguages.JAVA]: SL_CODE_SUPPORTED_LANGUAGES.JAVA,
	[CodeLanguages.PYTHON]: SL_CODE_SUPPORTED_LANGUAGES.PYTHON,
	[CodeLanguages.PHP]: SL_CODE_SUPPORTED_LANGUAGES.PHP,
	[CodeLanguages.RUBY]: SL_CODE_SUPPORTED_LANGUAGES.RUBY,
	[CodeLanguages.KOTLIN]: SL_CODE_SUPPORTED_LANGUAGES.KOTLIN,
	[CodeLanguages.SWIFT]: SL_CODE_SUPPORTED_LANGUAGES.SWIFT,
	[CodeLanguages.NODEJS]: SL_CODE_SUPPORTED_LANGUAGES.NODEJS,
	[CodeLanguages.WEB]: SL_CODE_SUPPORTED_LANGUAGES.WEB,
	[CodeLanguages.JQUERY]: SL_CODE_SUPPORTED_LANGUAGES.WEB,
	[CodeLanguages.CSS]: SL_CODE_SUPPORTED_LANGUAGES.CSS,
	[CodeLanguages.HTML]: SL_CODE_SUPPORTED_LANGUAGES.HTML,
	[CodeLanguages.JS]: SL_CODE_SUPPORTED_LANGUAGES.JAVASCRIPT,
	[CodeLanguages.SQL]: SL_CODE_SUPPORTED_LANGUAGES.SQL,
	[CodeLanguages.GO]: SL_CODE_SUPPORTED_LANGUAGES.GO,
	[CodeLanguages.R]: SL_CODE_SUPPORTED_LANGUAGES.R,
	[CodeLanguages.TS]: SL_CODE_SUPPORTED_LANGUAGES.TS,
};
