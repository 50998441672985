import { ProgrammingLanguage } from '../../../symphony';
import { CodeLanguages } from '../../../api/private/global.interface';
import { CodeSnippetLanguage } from '../../../api/private/learn-engine-api/learn-engine-api.interface';

export enum CODE_LANGUAGES_BY_ID {
    'html' = 1014,
    'css' = 1023,
    'js' = 1024,
    'cpp' = 1051,
    'php' = 1059,
    'sql' = 1060,
    'java' = 1068,
    'py' = 1073,
    'swift' = 1075,
    'cs' = 1080,
    'rb' = 1081,
    'jquery' = 1082,
    'c' = 1089,
    'angular-nestjs' = 1092,
    'data-science' = 1093,
    'machine-learning' = 1094,
    'react-redux' = 1097,
    'r' = 1147,
    'python-for-beginners' = 1157,
    'intermediate-python' = 1158,
    'python-data-structures' = 1159,
    'kt' = 1160,
    'python-for-data-science' = 1161,
    'responsive-web-design' = 1162,
    'go' = 1164,
    'coding-for-marketers' = 1165,
    'simplified-python' = 1169,
}
export const IMAGE_DOWNLOAD_URL = 'https://api.sololearn.com/DownloadFile?id=';

// TODO: Understand why we need All language
export const ProgrammingLanguageToCodeLanguages = {
	[ProgrammingLanguage.Cpp]: CodeLanguages.CPP,
	[ProgrammingLanguage.C]: CodeLanguages.C,
	[ProgrammingLanguage.Cs]: CodeLanguages.CSharp,
	[ProgrammingLanguage.Java]: CodeLanguages.JAVA,
	[ProgrammingLanguage.Py]: CodeLanguages.PYTHON,
	[ProgrammingLanguage.Php]: CodeLanguages.PHP,
	[ProgrammingLanguage.Rb]: CodeLanguages.RUBY,
	[ProgrammingLanguage.Kt]: CodeLanguages.KOTLIN,
	[ProgrammingLanguage.Swift]: CodeLanguages.SWIFT,
	[ProgrammingLanguage.Node]: CodeLanguages.NODEJS,
	[ProgrammingLanguage.Js]: CodeLanguages.JS,
	[ProgrammingLanguage.Sql]: CodeLanguages.SQL,
	[ProgrammingLanguage.Go]: CodeLanguages.GO,
	[ProgrammingLanguage.R]: CodeLanguages.R,
	[ProgrammingLanguage.Css]: CodeLanguages.CSS,
	[ProgrammingLanguage.Html]: CodeLanguages.HTML,
	[ProgrammingLanguage.Web]: CodeLanguages.WEB,
	[ProgrammingLanguage.TS]: CodeLanguages.TS,
};

export const CodeLanguagesToProgrammingLanguage = {
	[CodeLanguages.CPP]: ProgrammingLanguage.Cpp,
	[CodeLanguages.C]: ProgrammingLanguage.C,
	[CodeLanguages.CSharp]: ProgrammingLanguage.Cs,
	[CodeLanguages.JAVA]: ProgrammingLanguage.Java,
	[CodeLanguages.PYTHON]: ProgrammingLanguage.Py,
	[CodeLanguages.PHP]: ProgrammingLanguage.Php,
	[CodeLanguages.RUBY]: ProgrammingLanguage.Rb,
	[CodeLanguages.KOTLIN]: ProgrammingLanguage.Kt,
	[CodeLanguages.SWIFT]: ProgrammingLanguage.Swift,
	[CodeLanguages.NODEJS]: ProgrammingLanguage.Node,
	[CodeLanguages.JS]: ProgrammingLanguage.Js,
	[CodeLanguages.SQL]: ProgrammingLanguage.Sql,
	[CodeLanguages.GO]: ProgrammingLanguage.Go,
	[CodeLanguages.R]: ProgrammingLanguage.R,
	[CodeLanguages.CSS]: ProgrammingLanguage.Css,
	[CodeLanguages.HTML]: ProgrammingLanguage.Html,
	[CodeLanguages.WEB]: ProgrammingLanguage.Web,
	[CodeLanguages.TS]: ProgrammingLanguage.TS,
};

export const ProgrammingLanguageToCodeSnippetLanguage = {
	[ProgrammingLanguage.Cpp]: CodeSnippetLanguage.CPP,
	[ProgrammingLanguage.C]: CodeSnippetLanguage.C,
	[ProgrammingLanguage.Cs]: CodeSnippetLanguage.CSHARP,
	[ProgrammingLanguage.Java]: CodeSnippetLanguage.Java,
	[ProgrammingLanguage.Py]: CodeSnippetLanguage.Python,
	[ProgrammingLanguage.Php]: CodeSnippetLanguage.PHP,
	[ProgrammingLanguage.Rb]: CodeSnippetLanguage.Ruby,
	[ProgrammingLanguage.Kt]: CodeSnippetLanguage.Kotlin,
	[ProgrammingLanguage.Swift]: CodeSnippetLanguage.Swift,
	[ProgrammingLanguage.Node]: CodeSnippetLanguage.NodeJS,
	[ProgrammingLanguage.Js]: CodeSnippetLanguage.JS,
	[ProgrammingLanguage.Sql]: CodeSnippetLanguage.SQL,
	[ProgrammingLanguage.Go]: CodeSnippetLanguage.Go,
	[ProgrammingLanguage.R]: CodeSnippetLanguage.R,
	[ProgrammingLanguage.Css]: CodeSnippetLanguage.CSS,
	[ProgrammingLanguage.Html]: CodeSnippetLanguage.HTML,
	[ProgrammingLanguage.TS]: CodeSnippetLanguage.TS,
};

export const REWARD_ANIMATION_DURATION = 1000;
