import { useEffect, useState } from 'react';
import { Container } from '../../../../symphony';

const iframeEnvMap = {
	prod: 'https://solocode.azurewebsites.net',
	dev: 'https://sl-pg-if-dev.netlify.app',
	local: 'http://127.0.0.1:5500/local.html', // you can set your local iframe src
};

export const useIFrameSrc = () => {
	const env: string = Container.take('global', 'env');

	return iframeEnvMap[env];
};
