import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';

import { IOutput } from '../types/types';
import { ICodeOutput } from '../../../../api/private/learn-engine-api/learn-engine-api.interface';
import { CodeLanguages } from '../../../../api/private/global.interface';

import s from './OutputText.scss';
import { lePlaygroundContext } from '../../../../lePlayground/private/global-constants';
import { Container } from '../../../../symphony';

export interface OutputTextProps {
	value: IOutput | ICodeOutput;
	isResizingPanel?: boolean;
}

export function OutputText({
	value,
	isResizingPanel,
}: OutputTextProps): JSX.Element {
	useStyles(s);

	const isTIYWidget = Container.take(
		lePlaygroundContext.value,
		'isTIYWidget',
	);

	const renderOutput = () => {
		const className = classNames('sl-playground-output-text sl-playground-output-text__paddings', {
			isTIYWidget,
		});

		if (!value) {
			return <div className={className} />;
		}

		if (value.language === CodeLanguages.PHP) {
			return (
				<iframe
					src={`data:text/html;charset=utf-8,${encodeURI(
						value.output,
					)}`}
					title="OutputText"
					className={className}
				/>
			);
		}
		return <pre className={className}>{value.output}</pre>;
	};

	return (
		<div
			className={classNames('sl-playground-output-text-wrapper', {
				'no-pointer': isResizingPanel,
			})}
		>
			{renderOutput()}
		</div>
	);
}
